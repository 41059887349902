import { useMemo } from "react";

interface XSProp {
  size: "0" | 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

interface SProp {
  size: "0" | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

interface MProp {
  size: "0" | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

type GridColumnProps = {
  children?: React.ReactNode;
  xs: XSProp;
  s: SProp;
  m: MProp;
};

const GridColumn = ({ children, xs, s, m }: GridColumnProps) => {
  const classNameString = useMemo(() => {
    const classNames = {
      [`r-grid-column-xs-${xs?.size}`]: Boolean(xs?.size),
      [`r-grid-column-s-${s?.size}`]: Boolean(s?.size),
      [`r-grid-column-m-${m?.size}`]: Boolean(m?.size),
    };

    return Object.entries(classNames)
      .filter(([_, value]) => value)
      .map(([key, _]) => key)
      .join(" ");
  }, [xs, s, m]);

  return <div className={classNameString}>{children}</div>;
};

export default GridColumn;
