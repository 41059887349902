import { RTicker, ValueProp } from "@ritual/essentials-for-react";
import { ReactElement } from "react";
import styled from "styled-components";

const ValuePropsTickerElement = styled.div`
  position: relative;
  overflow: visible;
`;

const TickerWrapper = styled.div`
  z-index: 10;
  position: relative;
`;

const ValuePropsTicker = ({ children }: { children: ReactElement }) => {
  return (
    <ValuePropsTickerElement>
      <TickerWrapper>
        <RTicker>
          <ValueProp
            slot="value-prop"
            name="Traceable Nutrients"
            icon-url="https://assets.ritual.com/icons/formula/warm/traceable-24x24.svg"
            icon-alt-text="Circle with an arrow running through to the right icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop"
            name="Third-Party Tested (for microbes and heavy metals)"
            icon-url="https://assets.ritual.com/icons/formula/warm/tested-24x24.svg"
            icon-alt-text="Shield icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop"
            name="Non-GMO"
            icon-url="https://assets.ritual.com/icons/formula/warm/non-gmo-24x24.svg"
            icon-alt-text="Disallow icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop"
            name="Vegan"
            icon-url="https://assets.ritual.com/icons/formula/warm/vegan-24x24.svg"
            icon-alt-text="Leaf sprout icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop"
            name="No Colorants"
            icon-url="https://assets.ritual.com/icons/formula/warm/no-colorants-24x24.svg"
            icon-alt-text="Water drop icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-1"
            name="Traceable Nutrients"
            icon-url="https://assets.ritual.com/icons/formula/warm/traceable-24x24.svg"
            icon-alt-text="Circle with an arrow running through to the right icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-1"
            name="Third-Party Tested (for microbes and heavy metals)"
            icon-url="https://assets.ritual.com/icons/formula/warm/tested-24x24.svg"
            icon-alt-text="Shield icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-1"
            name="Non-GMO"
            icon-url="https://assets.ritual.com/icons/formula/warm/non-gmo-24x24.svg"
            icon-alt-text="Disallow icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-1"
            name="Vegan"
            icon-url="https://assets.ritual.com/icons/formula/warm/vegan-24x24.svg"
            icon-alt-text="Leaf sprout icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-1"
            name="No Colorants"
            icon-url="https://assets.ritual.com/icons/formula/warm/no-colorants-24x24.svg"
            icon-alt-text="Water drop icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-2"
            name="Traceable Nutrients"
            icon-url="https://assets.ritual.com/icons/formula/warm/traceable-24x24.svg"
            icon-alt-text="Circle with an arrow running through to the right icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-2"
            name="Third-Party Tested (for microbes and heavy metals)"
            icon-url="https://assets.ritual.com/icons/formula/warm/tested-24x24.svg"
            icon-alt-text="Shield icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-2"
            name="Non-GMO"
            icon-url="https://assets.ritual.com/icons/formula/warm/non-gmo-24x24.svg"
            icon-alt-text="Disallow icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-2"
            name="Vegan"
            icon-url="https://assets.ritual.com/icons/formula/warm/vegan-24x24.svg"
            icon-alt-text="Leaf sprout icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-2"
            name="No Colorants"
            icon-url="https://assets.ritual.com/icons/formula/warm/no-colorants-24x24.svg"
            icon-alt-text="Water drop icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-3"
            name="Traceable Nutrients"
            icon-url="https://assets.ritual.com/icons/formula/warm/traceable-24x24.svg"
            icon-alt-text="Circle with an arrow running through to the right icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-3"
            name="Third-Party Tested (for microbes and heavy metals)"
            icon-url="https://assets.ritual.com/icons/formula/warm/tested-24x24.svg"
            icon-alt-text="Shield icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-3"
            name="Non-GMO"
            icon-url="https://assets.ritual.com/icons/formula/warm/non-gmo-24x24.svg"
            icon-alt-text="Disallow icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-3"
            name="Vegan"
            icon-url="https://assets.ritual.com/icons/formula/warm/vegan-24x24.svg"
            icon-alt-text="Leaf sprout icon"
          ></ValueProp>
          <ValueProp
            slot="value-prop-3"
            name="No Colorants"
            icon-url="https://assets.ritual.com/icons/formula/warm/no-colorants-24x24.svg"
            icon-alt-text="Water drop icon"
          ></ValueProp>
        </RTicker>
      </TickerWrapper>
      {children}
    </ValuePropsTickerElement>
  );
};

export default ValuePropsTicker;
