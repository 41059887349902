import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const FollowContainer = styled.div`
  height: 100%; /* Matches the height of its parent */
  position: relative;
`;

const ContentContainer = styled.div`
  position: absolute;
  width: 100%;
`;

type Props = {
  children: React.ReactNode;
};

const FollowElement = ({ children }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  if (!children) return null;

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const imageHeight = contentRef.current.offsetHeight;
        const scrollY = window.scrollY + 64;
        const containerTop = containerRect.top + window.scrollY;
        const containerBottom = containerRect.bottom + window.scrollY;

        if (
          scrollY >= containerTop &&
          scrollY <= containerBottom - imageHeight
        ) {
          contentRef.current.style.top = `${scrollY - containerTop}px`;
        } else if (scrollY < containerTop) {
          contentRef.current.style.top = "0px";
        } else if (scrollY > containerBottom - imageHeight) {
          contentRef.current.style.top = `${
            containerBottom - imageHeight - containerTop
          }px`;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call to set position
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <FollowContainer ref={containerRef}>
      <ContentContainer ref={contentRef}>{children}</ContentContainer>
    </FollowContainer>
  );
};

export default FollowElement;
