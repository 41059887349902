import styled from "styled-components";
import MagicLink from "../MagicLink";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import metrics from "../../utils/metrics";

const WhatsIncludedContent = styled.div`
  margin-bottom: var(--spacing-5);

  @media (min-width: 933px) {
    margin-bottom: var(--spacing-6);
  }
`;

const Title = styled.h2`
  font-family: var(--font-circular) !important;
  font-size: 32px;
  line-height: 40px;
  font-weight: 450 !important;
  letter-spacing: -1%;
  margin-bottom: var(--spacing-2);

  @media (min-width: 933px) {
    font-size: 40px;
    line-height: 50px;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  margin-bottom: var(--spacing-1_5);

  &:last-child {
    margin-bottom: 0;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--spacing-1);

    img {
      transition: transform 0.2s ease-in-out;
    }

    &:hover {
      img {
        transform: translateX(4px);
      }

      img:not(.icon) {
        transform: scale(1.2);
      }
    }
  }
`;

const Image = styled.div`
  width: 72px;
  height: 72px;
  flex: 0 0 72px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 933px) {
    width: 80px;
    height: 80px;
    flex: 0 0 80px;
  }
`;

const Product = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Icon = styled.div`
  width: 80px;
  height: 80px;
  flex: 0 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
  }
`;

type Product = {
  path: string;
  name: {
    name: string;
  };
  summary: string;
  productSubhead: string;
  cartImage: {
    description: string;
    gatsbyImageData: IGatsbyImageData;
  };
};

type Props = {
  products: Product[];
};

const WhatsIncluded = ({ products }: Props) => {
  const onClickHandler = (product: Product) => {
    metrics.track("CTA Clicked", {
      location: "What's Included",
      title: product.name.name,
    });
  };

  return (
    <WhatsIncludedContent>
      <Title>What's Included</Title>
      <List>
        {products.map((product) => (
          <ListItem key={product.path}>
            <MagicLink
              to={`/${product.path}`}
              title={product.summary}
              onClick={() => onClickHandler(product)}
            >
              <Image>
                <GatsbyImage
                  image={product.cartImage.gatsbyImageData}
                  alt={product.cartImage.description}
                  imgStyle={{
                    transition: "transform 0.2s ease-in-out",
                  }}
                />
              </Image>
              <Product>
                <span className="typography-label1">{product.summary}</span>
                <span
                  className="typography-body2 font-circular"
                  style={{ color: "var(--indigo-blue-60)" }}
                >
                  {product.productSubhead}
                </span>
              </Product>
              <Icon>
                <img
                  className="icon"
                  src="https://assets.ritual.com/icons/arrow-right.svg"
                  alt="Arrow pointing to the right"
                />
              </Icon>
            </MagicLink>
          </ListItem>
        ))}
      </List>
    </WhatsIncludedContent>
  );
};

export default WhatsIncluded;
