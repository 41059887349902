import styled from "styled-components";
import intl from "../../services/intl";
import { Icons } from "../../utils/react-svg";
import { ProductSKU } from "../../utils/product";
import Text from "../Text";

const Container = styled.div`
  margin-bottom: var(--spacing-3);
`;

const Heading = styled.p.attrs({
  className: "typography-eyebrow2 font-circular",
})`
  margin-bottom: var(--spacing-1);
  color: var(--indigo-blue-60);
`;

const BenefitContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:last-of-type) {
    margin-bottom: var(--spacing-1);
  }
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
  display: flex;
  margin-right: var(--spacing-0_75);
  flex: 0 0 auto;

  svg {
    color: var(--yellow-ochre);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    path,
    circle {
      fill: currentColor;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  p:first-of-type {
    color: var(--indigo-blue);
    margin-bottom: var(--spacing-0_25);
    font-weight: 500;
  }

  p:last-of-type {
    color: var(--indigo-blue-60);
    font-weight: 450;
  }
`;

type Product = {
  sku: string;
};

type Props = {
  products: Product[];
};

const Benefits = ({ products }: Props) => {
  const currentLocale = intl.locale;
  const epreSkus = [ProductSKU.EPRE60, ProductSKU.EPRE60M];

  const showFsaHsa =
    products.some((product) => epreSkus.includes(product.sku)) &&
    ["en-US", "en-CA"].includes(currentLocale);

  const benefits = [
    {
      icon: "Freeshipping", // TODO: Change to box icon
      benefit: "Bundle & Save",
      description: "Save up to 35% on your first month by bundling.",
    },
    {
      icon: "Recurring",
      benefit: "Delivered Monthly",
      description:
        "Convenient, free monthly shipping. Cancel and change deliveries as you need.",
    },
    {
      icon: "CircleStar",
      benefit: "30 Day Money-Back Guarantee",
      description: "Don't love it within 30 days? It's on us.",
    },
  ];

  return (
    <Container>
      <Heading>
        {Text({
          id: "product.hero.subscription-benefits.heading",
          defaultMessage: "Good Value(s)",
          values: null,
        })}
      </Heading>
      {benefits.map((benefit, i) => {
        const Icon = Icons[benefit.icon];
        return (
          <BenefitContainer key={i}>
            <IconContainer>
              <Icon />
            </IconContainer>
            <TextContainer>
              <p className="typography-label2">{benefit.benefit}</p>
              <p className="typography-body3 font-circular">
                {benefit.description}
              </p>
            </TextContainer>
          </BenefitContainer>
        );
      })}
      {showFsaHsa && (
        <BenefitContainer>
          <IconContainer>
            <Icons.Goodstuff />
          </IconContainer>
          <TextContainer>
            <p className="typography-label2">FSA/HSA Copy</p>
            <p className="typography-body3 font-circular">
              FSA/HSA Description
            </p>
          </TextContainer>
        </BenefitContainer>
      )}
    </Container>
  );
};

export default Benefits;
