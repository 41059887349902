import { graphql } from "gatsby";
import ValuePropsTicker from "../components/plp/ValuePropsTicker";
import GridContainer from "../components/grid/GridContainer";
import GridRow from "../components/grid/GridRow";
import GridColumn from "../components/grid/GridColumn";
import styled from "styled-components";
import Hero from "../components/bundle/Hero";
import WhatsIncluded from "../components/bundle/WhatsIncluded";
import { responsive } from "../utils/style";
import Benefits from "../components/subscription/Benefits";
import AddBundleButton from "../components/bundle/AddBundleButton";
import { useSelector } from "react-redux";
import { productOfferForId } from "../store/product-offer/selectors";
import { getStore } from "../store/createStore";
import FollowElement from "../components/FollowElement";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Background = styled.div`
  background-color: var(--warm-20);
`;

const MobileHeroImage = styled.div`
  position: relative;
  display: block;
  margin-top: -10%;
  margin-bottom: -15%;

  @media (min-width: 933px) {
    display: none;
  }
`;

const DesktopHeroImage = styled.div`
  display: none;

  @media (min-width: 933px) {
    display: block;
    height: 100%;
  }
`;

const Spacer = styled.div`
  margin-top: 58px;
  padding-top: 0;

  ${responsive.md`
    margin-top: 70px;
  `}

  @media (min-width: 577px) {
    padding-top: var(--spacing-3);
  }
`;

const BundlePageTemplate = (props) => {
  const { data } = props;
  const {
    flag,
    title,
    description,
    heroDescription,
    heroImage,
    ritualProductOffer,
    products,
  } = data.contentfulProductBundle;

  const productOfferRedux = useSelector((state) =>
    productOfferForId(state, ritualProductOffer?.product_offer_id),
  );

  const price = productOfferRedux.initialAmount(getStore().getState()) / 100;
  const futurePrice =
    productOfferRedux.futureAmount(getStore().getState()) / 100;
  const savings = Math.floor(((futurePrice - price) * 100) / 100).toFixed(0);

  const hasOOS = !!products.find(
    (product) => product.stockStatus === "out of stock",
  );

  return (
    <Background>
      <Spacer />
      <GridContainer>
        <GridRow>
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
            <MobileHeroImage>
              <GatsbyImage
                image={getImage(heroImage?.gatsbyImageData)}
                alt={heroImage?.description}
                style={{
                  width: "100%",
                  aspectRatio: "1 / 1",
                }}
              />
            </MobileHeroImage>
          </GridColumn>
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 5 }}>
            <Hero
              flag={flag}
              title={title}
              description={description}
              heroDescription={heroDescription}
              price={price}
              futurePrice={futurePrice}
              savings={savings}
            >
              <AddBundleButton
                ritualProductOffer={ritualProductOffer}
                oos={hasOOS}
                location={"Bundle PDP Hero"}
              />
            </Hero>
            <Benefits products={products} />
            <WhatsIncluded products={products} />
          </GridColumn>
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 1 }} />
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 6 }}>
            <DesktopHeroImage>
              <FollowElement>
                <GatsbyImage
                  image={getImage(heroImage?.gatsbyImageData)}
                  alt={heroImage?.description}
                  style={{
                    width: "100%",
                    aspectRatio: "1 / 1",
                  }}
                />
              </FollowElement>
            </DesktopHeroImage>
          </GridColumn>
        </GridRow>
      </GridContainer>
      <ValuePropsTicker />
    </Background>
  );
};

export default BundlePageTemplate;

export const query = graphql`
  query ProductBundlePageQuery($locale: String!, $slug: String!) {
    contentfulProductBundle(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      flag
      title
      description
      heroDescription {
        heroDescription
      }
      heroImage {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1200
          quality: 90
        )
        description
      }
      ritualProductOffer {
        product_offer_id
      }
      products {
        path
        name {
          name
        }
        summary
        productSubhead
        cartImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 240
            quality: 90
          )
          description
        }
      }
    }
  }
`;
