import { useDispatch } from "react-redux";
import { addProductToCart } from "../../store/cart/actions";
import cartService from "../../services/cart";
import DiscountButton from "./DiscountButton";
import RitualButton from "../global/RitualButton";

type Props = {
  ritualProductOffer?: {
    product_offer_id: string;
  };
  location: string;
  oos: boolean;
};

const AddBundleButton = ({ ritualProductOffer, location, oos }: Props) => {
  const dispatch = useDispatch();

  if (!ritualProductOffer) return null;

  if (oos) {
    return (
      <RitualButton disabled={true} className="fullwidth">
        Out of Stock
      </RitualButton>
    );
  }

  const handleAddBundle = (e: any) => {
    dispatch(
      addProductToCart(
        {
          productOfferId: ritualProductOffer.product_offer_id,
        },
        {
          location,
        },
      ),
    );

    cartService.openCart(e);
  };

  const discountButtonProps = {
    to: "/cart",
    noNavigate: true,
    onClick: handleAddBundle,
    price: undefined,
    prefix: "Add to Cart",
    className: "fullwidth",
    showDash: false,
  };

  return <DiscountButton {...discountButtonProps} />;
};

export default AddBundleButton;
